import loadable from "@loadable/component"
import React from "react"

const FooterStandard = loadable(() => import("./FooterStandard/FooterStandard"))
const LogoFooter = loadable(() => import("./LogoFooter/LogoFooter"))

const Footer = ({ component }) => {
  switch (component?.variants) {
    case "standard":
      return <FooterStandard component={component} />
    case "logo":
      return <LogoFooter />
    default:
      return null
  }
}

export default Footer
